import s from "assets/scss/ProjectsSlider.module.scss"
import "assets/scss/slickReset.scss"
import React, { ReactElement, useEffect, useRef, useState } from "react"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

import cx from "classnames"
import { Link } from "react-router-dom"
import Slider from "react-slick"

import { useTranslation } from "react-i18next"
import { useCursorStore } from "store/cursorStore"
import { IProject } from "../models/IProject"
import ButtonCircle from "./ButtonCircle"
import IconArrowSlider from "./Icon/IconArrowSlider"
import IconArrowSquare from "./Icon/IconArrowSquare"
import MagneticButton from "./MagneticButton"
import ProjectCard from "./ProjectCard"

interface Props {
  projects: IProject[]
}

const ProjectsSlider: React.FC<Props> = ({ projects }) => {
  const sliderRef = useRef<Slider | null>(null)
  const [selected, setSelected] = useState(0)
  const cursorStore = useCursorStore()
  const { t } = useTranslation()

  useEffect(() => {
    sliderRef.current?.slickGoTo(selected)
  }, [selected])

  const settings = {
    variableWidth: true,
    infinite: false,
    speed: 800,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  // useEffect(() => {
  //   const slickTrackEl: HTMLDivElement | null =
  //     document.querySelector(".slick-track")

  //   if (slickTrackEl) slickTrackEl.style.transform = "translate3d(0,0,0)"
  // })

  return (
    <div className={s.projectsSlider}>
      <div className={s.navigation}>
        <button
          className={cx(s.btn, s.prev)}
          onClick={() => sliderRef.current?.slickNext()}
        >
          <div className={s.iconW}>
            <IconArrowSlider fill="#3B3B3B" scale={1.75} rotate={0} />
          </div>
        </button>
        <button
          className={cx(s.btn, s.next)}
          onClick={() => sliderRef.current?.slickPrev()}
        >
          <div className={s.iconW}>
            <IconArrowSlider fill="#3B3B3B" scale={1.75} rotate={180} />
          </div>
        </button>
      </div>
      <div
        className={s.sliderW}
        onMouseEnter={() => {
          cursorStore.toggleText()
          if (!cursorStore.slide) cursorStore.toggleSlide()
        }}
        onMouseLeave={() => {
          cursorStore.toggleDefault()
        }}
      >
        <Slider {...settings} ref={sliderRef}>
          {Array.isArray(projects) &&
            projects.map((project: IProject, i): ReactElement => {
              return (
                <div key={i}>
                  <ProjectCard key={i} project={{ ...project }} />
                </div>
              )
            })}
        </Slider>
      </div>

      <Link to={`/${t("routes.projects")}`} className={s.btnW}>
        <MagneticButton>
          <div className={s.magneticW}>
            <ButtonCircle
              icon={<IconArrowSquare fill="#3b3b3b" />}
              text={{
                part1: t("home.projects.btnText.p1"),
                part2: t("home.projects.btnText.p2"),
              }}
            ></ButtonCircle>
          </div>
        </MagneticButton>
      </Link>
    </div>
  )
}

export default ProjectsSlider
