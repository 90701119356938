import s from "assets/scss/Contact.module.scss"
import Img from "components/Img"
import { FormEvent, useEffect, useRef, useState } from "react"

import cx from "classnames"

import contact from "assets/img/contact.jpg"
import axios from "axios"
import ButtonCircle from "components/ButtonCircle"
import IconArrowSquare from "components/Icon/IconArrowSquare"
import MagneticButton from "components/MagneticButton"
import { Trans, useTranslation } from "react-i18next"
import { api } from "utils/types"
import { useNavigate } from "react-router"

const Contact = () => {
  const nameInputRef = useRef<HTMLInputElement>(null)
  const companyInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const messageInputRef = useRef<HTMLTextAreaElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const [name, setName] = useState<string | null>(null)
  const [company, setCompany] = useState<string | null>(null)
  const [phone, setPhone] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [message, setMessage] = useState<string | null>(null)

  const [confirmed, setConfirmed] = useState<boolean>(false)

  const [response, setResponse] = useState<{
    message: string | null
    success: boolean | null
  } | null>(null)
  const [responsed, setResponsed] = useState(false)

  const { t, i18n } = useTranslation()

  const handleConfirmation = () => {
    setConfirmed((prev) => !prev)
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    try {
      // 👇️ const data: CreateUserResponse
      const { data } = await axios.post<any>(
        `${api}contact.php`,
        { name, company, phone, email, message, language: i18n.language },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      setResponse({ ...data })
      setResponsed(true)
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message)
        // 👇️ error: AxiosError<any, any>
        return error.message
      } else {
        console.log("unexpected error: ", error)
        return "An unexpected error occurred"
      }
    }
  }

  const handleFocus = (e: FormEvent | any) => {
    e.preventDefault()

    if (e.type === "focus" && e) {
      e.target.labels[0].style.opacity = "0.1"
    } else if (e.type === "blur" && e) {
      e.target.labels[0].style.opacity = "0.3"
    }
  }

  const timerRef = useRef<any>(null)

  useEffect(() => {
    if (responsed) {
      timerRef.current = setTimeout(() => {
        setResponsed(false)
      }, 10000)
    }

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [responsed])

  const navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/iletisim")
    } else {
      navigate("/contact")
    }
  }, [i18n.language])

  return (
    <main className={s.contact}>
      <div className={s.top}>
        <div className={s.formW}>
          <h1 className={s.title}> {t("contact.title")}</h1>
          <div className={cx(s.imgW, s.mobileOnly)}>
            <Img src={contact} objectFit="cover" objectPosition="bottom"></Img>
          </div>
          <form className={s.form} ref={formRef} onSubmit={handleSubmit}>
            <div className={s.top}>
              <div className={s.inputW}>
                <label
                  className={cx(s.label, {
                    [s.hidden]: name,
                  })}
                  htmlFor="name"
                >
                  {t("contact.form.name")}
                </label>
                <input
                  className={s.input}
                  id="name"
                  type="text"
                  ref={nameInputRef}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  onChange={(e) => {
                    setName(e.currentTarget.value)
                  }}
                  required
                />
              </div>
              <div className={s.inputW}>
                <label
                  className={cx(s.label, {
                    [s.hidden]: company,
                  })}
                  htmlFor="company"
                >
                  {t("contact.form.company")}
                </label>
                <input
                  className={s.input}
                  id="company"
                  type="text"
                  ref={companyInputRef}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  onChange={(e) => {
                    setCompany(e.currentTarget.value)
                  }}
                  required
                />
              </div>
              <div className={s.inputW}>
                <label
                  className={cx(s.label, {
                    [s.hidden]: phone,
                  })}
                  htmlFor="phone"
                >
                  {t("contact.form.phone")}
                </label>
                <input
                  className={s.input}
                  id="phone"
                  type="tel"
                  ref={phoneInputRef}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  onChange={(e) => {
                    setPhone(e.currentTarget.value)
                  }}
                  onInput={(e: any) =>
                    (e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*?)\..*/g, "$1"))
                  }
                />
              </div>
              <div className={s.inputW}>
                <label
                  className={cx(s.label, {
                    [s.hidden]: email,
                  })}
                  htmlFor="email"
                >
                  {t("contact.form.email")}
                </label>
                <input
                  className={s.input}
                  id="email"
                  type="email"
                  ref={emailInputRef}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  onChange={(e) => {
                    setEmail(e.currentTarget.value)
                  }}
                  required
                />
              </div>
            </div>

            <div className={s.bottom}>
              <div className={s.left}>
                <div className={s.inputGroup}>
                  <div className={s.textareaW}>
                    <label className={s.label} htmlFor="message">
                      {t("contact.form.message")}
                    </label>
                    <textarea
                      className={s.textarea}
                      id="message"
                      ref={messageInputRef}
                      onChange={(e) => {
                        setMessage(e.currentTarget.value)
                      }}
                      required
                    />
                  </div>
                  <div className={s.confirmation}>
                    <div className={s.checkbox} onClick={handleConfirmation}>
                      <div
                        className={cx(s.inner, { [s.enabled]: confirmed })}
                      ></div>
                    </div>
                    <div className={s.text}>
                      <small className={s.small} onClick={handleConfirmation}>
                        {t("contact.form.privacy.p1")}{" "}
                      </small>
                      <a
                        className={s.link}
                        href={`${t("footer.privacy.url")}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {t("contact.form.privacy.p2")}
                      </a>
                    </div>
                    <div className={s.error}>
                      <p className={s.errorText}></p>
                    </div>
                  </div>
                </div>
                <MagneticButton>
                  <div className={cx(s.magneticW, { [s.enabled]: confirmed })}>
                    <button
                      className={cx(s.btnW, { [s.enabled]: confirmed })}
                      type="submit"
                    >
                      <ButtonCircle
                        text={{
                          part1: t("contact.form.btnText"),
                        }}
                        icon={<IconArrowSquare fill="#3b3b3b" />}
                      />
                    </button>
                  </div>
                </MagneticButton>
              </div>
            </div>
          </form>
          <div
            className={s.error}
            style={{
              color: response?.success ? "#3b3b3b" : "rgb(171, 0, 0)",
              opacity: responsed ? "0.7" : "0",
              transition: "0.4s all ease",
            }}
          >
            <p className={s.errorText}>{response?.message}</p>
          </div>
        </div>

        <div className={s.questionsW}>
          <div className={s.imgW}>
            <Img src={contact} objectFit="cover" objectPosition="bottom"></Img>
          </div>
          <div className={s.info}>
            <div className={s.text}>
              <h5 className={s.title}>{t("contact.moreInfo.title")}</h5>
              <p className={s.par}>
                <Trans
                  i18nKey="contact.moreInfo.text"
                  components={{
                    linkk: (
                      <a className={s.link} href="mailto:info@keyco.com.tr">
                        info@keyco.com.tr
                      </a>
                    ),
                    br: <br />,
                  }}
                >
                  {t("contact.moreInfo.text")}
                </Trans>
              </p>
            </div>

            <div className={s.links}>
              <div className={s.legal}>
                <a
                  className={s.link}
                  href={`${t("footer.kvkk.url")}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("footer.kvkk.ui")}
                </a>
                <a
                  className={s.link}
                  href={`${t("footer.privacy.url")}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("footer.privacy.ui")}
                </a>
                {/* <a
                  className={s.link}
                  href={`${t("footer.cookie.url")}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("footer.cookie.ui")}
                </a> */}
              </div>
              <div className={s.social}>
                <a
                  className={s.link}
                  href={`${t("social.instagram")}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  INSTAGRAM
                </a>
                {/* <a
                  className={s.link}
                  href={`${t("social.linkedin")}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  LINKEDIN
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.copyright}>
        <h6 className={s.allRights}>{t("contact.copyright")}</h6>
        <h6 className={s.signature}>
          Made by{" "}
          <a
            className={s.jdfx}
            href="https://justdesignfx.com"
            target="_blank"
            rel="noreferrer"
          >
            JUST DESIGN FX
          </a>
        </h6>
      </div>
    </main>
  )
}

export default Contact
