import React from "react"
import s from "../assets/scss/ProjectCard.module.scss"
import { IProject } from "../models/IProject"
import Img from "./Img"

interface Props {
  project: IProject
}

const ProjectCard: React.FC<Props> = ({ project }) => {
  return (
    <div className={s.card} key={project.id}>
      <div className={s.iconW}>
        <Img src={project.icon} objectFit="contain"></Img>
      </div>
      <span className={s.seperator}></span>
      <div className={s.text}>
        <h4 className={s.title}>{project.title}</h4>
        <h5 className={s.subtitle}>{project.subtitle}</h5>
      </div>
    </div>
  )
}

export default ProjectCard
