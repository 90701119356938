import React from "react"
import s from "../assets/scss/ButtonCircle.module.scss"

import cx from "classnames"

interface IText {
  part1?: any
  part2?: any
}

type Props = {
  icon: JSX.Element | string
  text?: IText | null
}

const ButtonCircle = ({ icon, text = null }: Props) => {
  return (
    <div className={s.buttonCircle}>
      {
        <div className={cx(s.btnInner, { [s.withText]: text })}>
          <div className={s.iconW}>{icon}</div>
          {text && (
            <p className={s.text}>
              {text.part1 && text.part1}
              {text.part2 && (
                <>
                  <br />
                  {text.part2}
                </>
              )}
            </p>
          )}
        </div>
      }
    </div>
  )
}

export default ButtonCircle
