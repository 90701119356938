import pdfPrivacy from "assets/pdf/GizlilikPolitikasi.pdf"
import pdfKvkk from "assets/pdf/KVKAydintlatmaMetni.pdf"

export const tr = {
  translation: {
    routes: {
      home: "",
      projects: "projeler",
      contact: "iletisim",
      blog: "blog",
    },
    home: {
      hero: {
        small:
          "Farklı sektörlerdeki deneyimleri araştırmaya dayalı bilgi birikimi ile birleştiriyoruz.",
      },
      whatWeDo: {
        title: "Ne Yapar?",
        knowHow: "know-how",
        p1: "Proje geliştirme aşamasındaki yatırımcıların ve firmaların finansman kuruluşlarının <bold>know-how</bold> ortağı olma vizyonunu benimseyerek yola çıkan Keyco., farklı sektörlerde deneyimi araştırmaya dayalı bilgi birikimi ile birleştirerek uluslararası ölçekte seçkin müşteri profili ile birleştiren butik bir <bold>iş geliştirme ajansıdır.</bold>",
        p2: "Keyco. yeni markasını oluşturmak isteyen girişimcilere ve tamamlanmış markalara <bold>performans artırıcı fizibilite, değerleme, konsept oluşumu, tasarım yönetimi, proje geliştirme, koordinasyon ve satış-pazarlama danışmanlığı</bold> konularında hizmet vermektedir.",
      },
      contactUs: {
        btnText: { p1: "Bize", p2: "Ulaşın" },
      },
      marquee: {
        text: "Hizmet Türleri",
      },
      accordion: {
        consulting: {
          title: { p1: "Yatırımcı", p2: "Danışmanlığı" },
          tags: { p1: "Consultancy" },
          content: {
            p1: "Yatırımcının dinamik pazardaki ideal yerini belirleyerek konumlanması için ihtiyaçlarına yönelik tüm <bold>Ar-Ge</bold> çalışmalarını ve finansal tahmin raporlarını hazırlıyoruz.",
            p2: "Güçlü ve fark yaratan, hedef kitlenizin kalbine dokunan stratejiler ile birlikte yol arkadaşlığı yapıyoruz.",
          },
        },
        brandArchitecture: {
          title: { p1: "Marka", p2: "Mimarlığı" },
          tags: { p1: "Corporate" },
          content: {
            p1: "<span>360° iş geliştirme desteği…</span> Tamamlanmış veya devam etmekte olan yeni marka ve işletmeler için, ticari ve yatırım hedeflerini analiz ederek tüm varlık değerlerini maksimize edecek çıkış haritaları hazırlıyoruz.",
            p2: "“İşletme stratejilerinizi bütünleyen pazarlama ve marka stratejilerinizin omurgasını, marka mimarisi oluşturur.”",
            list: {
              title: "Marka mimarlığı hizmet başlıkları:",
              items: {
                p1: "Fizibilite Değerlendirme",
                p2: "Finansal Tahmin Raporu",
                p3: "Hikayenin Yazılması",
                p4: "Konsept Tasarım Oluşturma",
                p5: "Tasarım Yönetimi",
                p6: "Proje Geliştirme",
                p7: "Stratejik Planlama",
                p8: "Koordinasyon",
                p9: "Satış Kanallarının Belirlenmesi",
                p10: "Pazar Konumlandırması",
                p11: "Satış ve Pazarlama Desteği",
              },
            },
          },
        },
        brandCoop: {
          title: { p1: "Marka", p2: "İş Birliği" },
          tags: { p1: "Collaborate", p2: "Communicate", p3: "Connect" },
          content: {
            p1: "Hedef kitlenize uygun doğru iş ortakları ile markanızı veya işletmenizi bir araya getirerek satışlarınızı artırmaya yönelik, karşılıklı fayda ile marka iş birlikleri oluşturuyoruz. Keyco. network’ünden faydalanarak yaratıcı iş fikirleri ile markalar arası know-how buluşturması yapıyoruz.",
            p2: "Markanızı büyütecek stratejileri oluşturarak gelişim yolculuğunuzda size rehberlik ediyoruz.",
          },
        },
      },
      projects: {
        title: "Projeler",
        btnText: { p1: "Tüm", p2: "Projeler" },
      },
    },
    contact: {
      title: "Başarı hikayenizi birlikte yazalım.",
      form: {
        name: "Adınız Soyadınız*",
        company: "Şirket Adınız*",
        phone: "Telefon Numarası",
        email: "Eposta Adresiniz*",
        message: "Mesajınız*",
        privacy: {
          p1: "Keyco. olarak gizliliğinizi koruruz. Kişisel bilgilerinizi yalnızca talep ettiğiniz hizmetlerimiz hakkında sizi bilgilendirmek için kullanırız. Tüm yükümlülükleri kabul ediyorum.",
          p2: "Gizlilik Koşulları",
          pdf: "",
        },
        btnText: "Gönder",
      },
      moreInfo: {
        title: "Başka sorularınız mı var?",
        text: "Daha detaylı bilgi almak isterseniz <linkk>info@keyco.com.tr</linkk> adresine eposta atabilirsiniz.",
      },
      copyright: "KEYCO. © 2022. TÜM HAKLARI SAKLIDIR.",
    },
    footer: {
      title: { p1: "Büyümeye ve gelişmeye", p2: "hazır mısınız?" },
      btnText: { p1: "Bize", p2: "Ulaşın" },
      subscribe: {
        title: "YENİ İÇERİKLERİ YAKALAMAK İÇİN KAYDOLUN.",
        text: "En son yayınlanan fikirler, tartışmalar ve makaleler hakkında düzenli güncellemeler almak için bültenimize abone olun.",
        label: "Eposta Adresiniz",
        confirmation: {
          p1: "Keyco. olarak gizliliğinizi koruruz. Kişisel bilgilerinizi yalnızca talep ettiğiniz hizmetlerimiz hakkında sizi bilgilendirmek için kullanırız. Tüm yükümlülükleri kabul ediyorum.",
          p2: "Gizlilik Koşulları",
          link: "/",
        },
      },
      copyright: "KEYCO. © 2022. TÜM HAKLARI SAKLIDIR.",
      kvkk: { ui: "KVKK POLİTİKASI", url: pdfKvkk },
      privacy: { ui: "GİZLİLİK KOŞULLARI", url: pdfPrivacy },
      cookie: { ui: "ÇEREZLER", url: "cookies" },
    },
    blog: {
      searchbarLabel: "İçerik Ara",
    },
    projects: {
      title: "Projeler",
    },
    menu: {
      projects: { ui: "Projeler", url: "projeler" },
      blog: { ui: "Blog", url: "blog" },
      contact: { ui: "İletişim", url: "iletisim" },
    },
    magnetCursor: {
      seeMore: "İncele",
      drag: "Kaydır",
      close: "Kapat",
    },
    social: {
      instagram: "https://instagram.com/keyco.company",
      linkedin: "https://linkedin.com/keyco.company",
    },
  },
}
