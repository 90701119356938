import { gsap } from "gsap"
import { ReactElement, useRef } from "react"

import s from "assets/scss/MagneticButton.module.scss"
import { useWindowSize } from "hooks"
import { breakpointTypes } from "utils/types"

// interface IMagneticButton {
//   children: Element
//   className: string
//   speed: number
//   tollerance: number
//   scale: number
//   debug: boolean
//   borderRadius: number
// }

type Props = {
  children: ReactElement
  className?: string
  speed?: number
  tollerance?: number
  scale?: number
  debug?: boolean
  borderRadius?: string
}

const MagneticButton = ({
  children,
  className,
  speed = 0.6,
  tollerance = 1,
  scale = 1.5,
  debug = false,
  borderRadius = "50%",
}: Props) => {
  const root = useRef<HTMLDivElement>(null)
  const item = useRef<HTMLSpanElement>(null)
  const hover = useRef<HTMLSpanElement>(null)
  const rootBound = useRef<any>(null)
  const itemBound = useRef<any>(null)
  const diffBound = useRef<any>({ x: 0, y: 0 })
  const windowSize = useWindowSize()

  const handleMouseEnter = () => {
    // cursorStore.toggleHide()
    gsap.killTweensOf(item.current)
    gsap.set(hover.current, {
      scale: scale,
      borderRadius,
      background: debug ? "rgba(0, 125, 255, .4)" : "transparent",
    })

    rootBound.current = root.current?.getBoundingClientRect()
    itemBound.current = item.current?.getBoundingClientRect()
    diffBound.current.x =
      (rootBound.current.width * scale - rootBound.current?.width) / 2
    diffBound.current.y =
      (rootBound.current.height * scale - rootBound.current.height) / 2
  }

  const handleMouseLeave = () => {
    // cursorStore.toggleDefault()

    gsap.killTweensOf(item.current)
    gsap.to(item.current, {
      x: 0,
      y: 0,
      ease: "power3.out(1.1, .4)",
      duration: 0.4,
    })
    gsap.set(hover.current, {
      scale: 1,
    })
  }

  const handleMouseMove = (e: any) => {
    const x = e.clientX || e.touches[0].clientX
    const y = e.clientY || e.touches[0].clientY

    const maxX =
      ((rootBound.current.width - itemBound.current.width) / 2) * tollerance
    const maxY =
      ((rootBound.current.height - itemBound.current.height) / 2) * tollerance

    const newX = gsap.utils.mapRange(
      0,
      rootBound.current.width * scale,
      -maxX,
      maxX,
      x - rootBound.current.x + diffBound.current.x
    )

    const newY = gsap.utils.mapRange(
      0,
      rootBound.current.height * scale,
      -maxY,
      maxY,
      y - rootBound.current.y + diffBound.current.y
    )

    gsap.killTweensOf(item.current)
    gsap.to(item.current, {
      x: newX,
      y: newY,
      ease: "power3.out",
      duration: speed,
    })
  }

  return (
    <>
      {windowSize.width > breakpointTypes.tablet ? (
        <div
          ref={root}
          className={s.magneticButton}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseMove}
          onTouchMove={handleMouseMove}
          onTouchStart={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchEnd={handleMouseLeave}
        >
          <span ref={item} className={s.inner}>
            {children}
          </span>
          <span ref={hover} className={s.hover} />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default MagneticButton
