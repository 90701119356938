import { FormEvent, useEffect, useRef, useState } from "react"
import s from "../assets/scss/Footer.module.scss"

import axios from "axios"
import cx from "classnames"
import { Link } from "react-router-dom"

import { useTranslation } from "react-i18next"
import { api } from "utils/types"
import ButtonCircle from "./ButtonCircle"
import IconArrowSquare from "./Icon/IconArrowSquare"
import MagneticButton from "./MagneticButton"

const Footer = () => {
  const { t, i18n } = useTranslation()
  const emailInputRef = useRef<HTMLInputElement>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [response, setResponse] = useState<{
    message: string | null
    success: boolean | null
  } | null>(null)
  const [responsed, setResponsed] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  const handleConfirmation = () => {
    setConfirmed((prev) => !prev)
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      // 👇️ const data: CreateUserResponse
      const { data } = await axios.post<any>(
        `${api}subscribe.php`,
        { email, language: i18n.language },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      // console.log(data)
      setResponse({ ...data })
      setResponsed(true)

      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message)
        // 👇️ error: AxiosError<any, any>
        return error.message
      } else {
        console.log("unexpected error: ", error)
        return "An unexpected error occurred"
      }
    }
  }

  const handleFocus = (e: FormEvent | any) => {
    e.preventDefault()

    if (e.type === "focus" && e) {
      e.target.labels[0].style.opacity = "0.1"
    } else if (e.type === "blur" && e) {
      e.target.labels[0].style.opacity = "0.5"
    }
  }

  const timerRef = useRef<any>(null)

  useEffect(() => {
    if (responsed) {
      timerRef.current = setTimeout(() => {
        setResponsed(false)
      }, 10000)
    }

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [responsed])

  return (
    <footer className={s.footer}>
      <div className={s.top}>
        <div className={s.contact}>
          <h2 className={s.title}>
            {t("footer.title.p1")} <br /> {t("footer.title.p2")}
          </h2>
          <Link to={`/${t("routes.contact")}`} className={s.btnW}>
            <MagneticButton>
              <div className={s.magneticW}>
                <ButtonCircle
                  icon={<IconArrowSquare fill="#3b3b3b" />}
                  text={{
                    part1: t("footer.btnText.p1"),
                    part2: t("footer.btnText.p2"),
                  }}
                />
              </div>
            </MagneticButton>
          </Link>
        </div>
        <div className={s.subscribe}>
          <h5 className={s.title}>{t("footer.subscribe.title")}</h5>
          <p className={s.par}>{t("footer.subscribe.text")}</p>
          <form className={s.form} onSubmit={handleSubmit}>
            <div className={s.inputW}>
              <label
                className={cx(s.label, {
                  [s.hidden]: email,
                })}
                htmlFor="email"
              >
                {t("footer.subscribe.label")}
              </label>
              <input
                ref={emailInputRef}
                className={s.input}
                id="email"
                type="email"
                onChange={(e) => {
                  setEmail(e.currentTarget.value)
                }}
                onFocus={handleFocus}
                onBlur={handleFocus}
                required
              />
              <button
                type="submit"
                className={cx(s.iconW, { [s.enabled]: confirmed })}
              >
                <IconArrowSquare fill="#3b3b3b" rotate={-45}></IconArrowSquare>
              </button>
            </div>
            <div className={s.confirmation}>
              <div className={s.checkbox} onClick={handleConfirmation}>
                <div className={cx(s.inner, { [s.enabled]: confirmed })}></div>
              </div>
              <div className={s.text}>
                <small className={s.small} onClick={handleConfirmation}>
                  {t("footer.subscribe.confirmation.p1")}{" "}
                </small>
                <a
                  className={s.link}
                  href={`${t("footer.privacy.url")}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {t("footer.subscribe.confirmation.p2")}
                </a>
              </div>
            </div>
          </form>
          <div
            className={s.error}
            style={{
              color: response?.success ? "#3b3b3b" : "rgb(171, 0, 0)",
              opacity: responsed ? "0.7" : "0",
              transition: "0.4s all ease",
            }}
          >
            <p className={s.errorText}>{response?.message}</p>
          </div>
        </div>
      </div>
      <div className={s.bottom}>
        <div className={s.links}>
          <div className={s.lol}>
            <a
              className={s.link}
              href={`${t("footer.kvkk.url")}`}
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.kvkk.ui")}
            </a>
            <a
              className={s.link}
              href={`${t("footer.privacy.url")}`}
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.privacy.ui")}
            </a>
            {/* <a
              className={s.link}
              href={`${t("footer.cookie.url")}`}
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.cookie.ui")}
            </a> */}
          </div>
          <div className={s.social}>
            <a
              href={`${t("social.instagram")}`}
              target="_blank"
              rel="noreferrer"
              className={s.link}
            >
              INSTAGRAM
            </a>
            {/* <a
              href={`${t("social.linkedin")}`}
              target="_blank"
              rel="noreferrer"
              className={s.link}
            >
              LINKEDIN
            </a> */}
          </div>
        </div>
        <div className={s.copyright}>
          <h6 className={s.allRights}>{t("footer.copyright")}</h6>
          <h6 className={s.signature}>
            Made by{" "}
            <a
              className={s.jdfx}
              href="https://justdesignfx.com"
              target="_blank"
              rel="noreferrer"
            >
              JUST DESIGN FX
            </a>
          </h6>
        </div>
      </div>
    </footer>
  )
}

export default Footer
