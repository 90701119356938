import React from "react";

type Props = {
  fill: string;
  rotate?: number;
  scale?: number;
};

const IconArrowSquare = ({ fill = "#000", rotate = 0, scale = 1 }: Props) => {
  return (
    <div
      style={{
        transform: `rotate(${rotate}deg) scale(${scale})`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path
          id="Path_48"
          data-name="Path 48"
          d="M274.238,461.711h15.805a.34.34,0,0,0,.366-.306V445.6a.392.392,0,0,0-.366-.366h-3.477v10.005l-11.533-11.531-2.624,2.687,11.533,11.531H273.933v3.477a.289.289,0,0,0,.3.306Z"
          transform="translate(-272.409 -443.711)"
          fill={fill}
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconArrowSquare;
