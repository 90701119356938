import gsap from "gsap"
import { useEffect, useRef, useState } from "react"

import s from "assets/scss/Preloader.module.scss"
import cx from "classnames"
import { useLockedBody } from "hooks"

export default function Preloader() {
  const preloaderWrapperRef = useRef(null)
  const [progress, setProgress] = useState(0)
  const [open, setOpen] = useState(true)
  const [, setLocked] = useLockedBody(false, "root")

  useEffect(() => {
    if (open) {
      setLocked(true)
    } else {
      setLocked(false)
    }
  }, [open])

  const up = () => {
    setOpen(false)
  }

  // Image load counter
  useEffect(() => {
    let img: HTMLCollectionOf<HTMLImageElement>, c: number, tot: number

    img = document.images
    c = 0
    tot = img.length

    const imgLoaded = () => {
      c += 1
      setProgress(((100 / tot) * c) << 0)

      if (c === tot) return up()
      if (c === tot) return
    }

    const counter = () => {
      for (var i = 0; i < tot; i++) {
        var tImg = new Image()
        tImg.onload = imgLoaded
        tImg.onerror = imgLoaded
        tImg.src = img[i].src
      }
    }

    counter()
  }, [])

  return (
    <div
      className={cx(s.preloaderW, { [s.hide]: !open })}
      ref={preloaderWrapperRef}
    >
      <div className={cx(s.progress, { [s.hide]: !open })}>{progress}%</div>
      <div
        className={cx(s.shape, { [s.hide]: !open })}
        style={{
          opacity: `${gsap.utils.mapRange(0, 100, 0, 1, progress)}`,
        }}
      ></div>
    </div>
  )
}
