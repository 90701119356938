import React, { useEffect, useState } from "react"
import s from "assets/scss/Accordion.module.scss"

import cx from "classnames"

import Img from "components/Img"
import plus from "assets/icon/plus.svg"
import minus from "assets/icon/minus.svg"

import accBrandArch from "assets/img/acc-abstract.jpg"
import accBrandCoopH from "assets/img/acc-brand-coop-h.png"
import accBrandCoopV from "assets/img/acc-brand-coop-v.png"
import accConsultancy from "assets/img/acc-consultancy.jpg"

import IconBulletPoint from "components/Icon/IconBulletPoint"
import { Trans, useTranslation } from "react-i18next"
import { useCursorStore } from "store/cursorStore"

const obj = {
  consulting: "consulting",
  brandArchitecture: "brandArchitecture",
  brandCoop: "brandCoop",
}

type ObjectKey = keyof typeof obj

interface IAccordionItem {
  icon: string
  title: { part1: string; part2: string }
  tags: string[]
  content: ObjectKey
  [x: string]: any
}

interface Props {
  items: IAccordionItem[]
}

const AccordionItem = (props: IAccordionItem) => {
  const { t } = useTranslation()
  const cursorStore = useCursorStore()
  const [isOpen, setIsOpen] = useState(false)

  const contents = {
    consulting: (
      <>
        <div className={s.imgW} data-scaling-img-wr>
          <div data-scaling-img>
            <Img src={accConsultancy} objectFit="cover"></Img>
          </div>
        </div>
        <div className={s.text}>
          <h3 className={s.title}>
            <Trans
              i18nKey="home.accordion.consulting.content.p1"
              components={{ bold: <strong /> }}
            >
              {t("home.accordion.consulting.content.p1")}
            </Trans>
          </h3>
          <p className={s.par}>{t("home.accordion.consulting.content.p2")}</p>
        </div>
      </>
    ),
    brandArchitecture: (
      <>
        <div className={s.top}>
          <p className={s.par1}>
            <Trans
              i18nKey="home.accordion.brandArchitecture.content.p1"
              components={{ span: <span /> }}
            >
              {t("home.accordion.brandArchitecture.content.p1")}
            </Trans>
          </p>
          <p className={s.par2}>
            {t("home.accordion.brandArchitecture.content.p2")}
          </p>
        </div>
        <div className={s.bottom}>
          <div className={s.imgW} data-scaling-img-wr>
            <div data-scaling-img>
              <Img src={accBrandArch} objectFit="cover"></Img>
            </div>
          </div>
          <div className={s.listW}>
            <h6 className={s.title}>
              {t("home.accordion.brandArchitecture.content.list.title")}
            </h6>
            <div className={s.lists}>
              <ul className={s.list}>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p1"
                    )}
                  </p>
                </li>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p2"
                    )}
                  </p>
                </li>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p3"
                    )}
                  </p>
                </li>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p4"
                    )}
                  </p>
                </li>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p5"
                    )}
                  </p>
                </li>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p6"
                    )}
                  </p>
                </li>
              </ul>
              <ul className={s.list}>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p7"
                    )}
                  </p>
                </li>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p8"
                    )}
                  </p>
                </li>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p9"
                    )}
                  </p>
                </li>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p10"
                    )}
                  </p>
                </li>
                <li className={s.item}>
                  <div className={s.iconW}>
                    <IconBulletPoint
                      scale={0.65}
                      fill="#8A3DAF"
                    ></IconBulletPoint>
                  </div>
                  <p className={s.text}>
                    {t(
                      "home.accordion.brandArchitecture.content.list.items.p11"
                    )}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    ),
    brandCoop: (
      <>
        <div className={s.text}>
          <p className={s.par1}>{t("home.accordion.brandCoop.content.p1")}</p>
          <p className={s.par2}>{t("home.accordion.brandCoop.content.p2")}</p>
        </div>

        <div className={s.imgs}>
          <div className={s.imgW} data-scaling-img-wr>
            <div data-scaling-img>
              <Img src={accBrandCoopV} objectFit="cover"></Img>
            </div>
          </div>
          <div className={s.imgW} data-parallax data-speed="0.1">
            <Img src={accBrandCoopH} objectFit="cover"></Img>
          </div>
        </div>
      </>
    ),
  }

  const handleSelect = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    cursorStore.toggleSeeMore()
  }, [isOpen])

  return (
    <div
      className={s.item}
      data-accordion-item
      onMouseEnter={() => {
        if (isOpen) {
          if (!cursorStore.seeMore) cursorStore.toggleSeeMore()
        }
      }}
      onMouseOver={() => {
        if (isOpen) {
          if (!cursorStore.seeMore) cursorStore.toggleSeeMore()
        }
        if (!cursorStore.texted) cursorStore.toggleText()
      }}
      onMouseLeave={() => {
        cursorStore.toggleDefault()
      }}
    >
      <div
        className={cx(s.header, {
          [s.active]: isOpen,
        })}
        onClick={handleSelect}
      >
        <div className={s.iconW}>
          <Img objectFit="contain" src={props.icon}></Img>
        </div>
        <div className={s.text}>
          <div className={s.titleW}>
            <h3 className={s.title}>{props.title.part1}</h3>
            <h3 className={s.title}>{props.title.part2}</h3>
          </div>
          <small className={s.tags}>
            {props.tags.map((tag, i) => {
              return (
                <div key={i} className={s.tag}>
                  {tag}
                </div>
              )
            })}
          </small>
        </div>
        <button className={s.btn}>
          <div
            className={cx(s.iconW, s.plus, {
              [s.active]: isOpen,
            })}
          >
            <Img src={plus} objectFit="contain"></Img>
          </div>
          <div
            className={cx(s.iconW, s.minus, {
              [s.active]: isOpen,
            })}
          >
            <Img src={minus} objectFit="contain"></Img>
          </div>
        </button>
      </div>
      <div
        className={cx(s.oWrapper, {
          [s.active]: isOpen,
        })}
      >
        <div
          className={cx(s.content, {
            [s.consulting]: props.content === obj.consulting,
            [s.brandArchitecture]: props.content === obj.brandArchitecture,
            [s.brandCoop]: props.content === obj.brandCoop,
          })}
          onClick={() => props.handleSelect(props.index)}
        >
          {contents[props.content]}
        </div>
      </div>
    </div>
  )
}

const Accordion = ({ items }: Props) => {
  // const [currentItem, setCurrentItem] = useState<number | null>(null)

  // const handleSelect = (index: number) => {
  //   if (index === currentItem) {
  //     return setCurrentItem(null)
  //   }
  //   setCurrentItem(index)
  // }

  return (
    <div className={s.accordion}>
      {items.map((item, i) => {
        return (
          <AccordionItem
            key={i}
            {...item}
            // index={i}
            // currentItem={currentItem}
            // handleSelect={handleSelect}
          />
        )
      })}
    </div>
  )
}

export default Accordion
