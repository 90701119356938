import React, { useEffect, useState } from "react"
import s from "../assets/scss/Projects.module.scss"

import Footer from "../components/Footer"
import { IProject } from "../models/IProject"
import axios from "axios"
import Img from "components/Img"
import { api } from "utils/types"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

const Projects = () => {
  const { i18n, t } = useTranslation()
  // const projects = [
  //   {
  //     icon: dummy,
  //     title: "Makers Unite",
  //     subtitle: "Türkiye İş Geliştirme Danışmanlığı",
  //   },
  //   {
  //     icon: dummy,
  //     title: "Makers Unite",
  //     subtitle: "Türkiye İş Geliştirme Danışmanlığı",
  //   },
  //   {
  //     icon: dummy,
  //     title: "Makers Unite",
  //     subtitle: "Türkiye İş Geliştirme Danışmanlığı",
  //   },
  // ];

  const [projects, setProjects] = useState<IProject[]>([])

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        // 👇️ const data: CreateUserResponse
        const { data } = await axios.get<any>(`${api}projects.php`, {
          params: {
            language: i18n.language,
          },
        })
        setProjects([...data])
        return data
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message)
          // 👇️ error: AxiosError<any, any>
          return error.message
        } else {
          console.log("unexpected error: ", error)
          return "An unexpected error occurred"
        }
      }
    }

    fetchProjects()
  }, [i18n.language])

  const navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/projeler")
    } else {
      navigate("/projects")
    }
  }, [i18n.language])

  return (
    <>
      <main className={s.projects}>
        <div className={s.doubleTitleW}>
          <h2 className={s.title} data-parallax data-speed="0.2">
            {t("projects.title")}
          </h2>
          <h2 className={s.title} data-parallax data-speed="0.2">
            {t("projects.title")}
          </h2>
        </div>
        <div className={s.projectsList}>
          {Array.isArray(projects) &&
            projects.map((project, i) => {
              return (
                <div className={s.projectCard} key={i}>
                  <div className={s.iconW}>
                    <Img src={project.icon} objectFit="contain"></Img>
                  </div>
                  <span className={s.seperator}></span>
                  <div className={s.text}>
                    <h4 className={s.title}>{project.title}</h4>
                    <h5 className={s.subtitle}>{project.subtitle}</h5>
                  </div>
                </div>
              )
              // return <ProjectCard project={project} key={i}></ProjectCard>;
            })}
        </div>
      </main>
      <Footer></Footer>
    </>
  )
}

export default Projects
