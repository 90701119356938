import React, { useEffect, useRef } from "react"
import s from "assets/scss/MagnetCursor.module.scss"
import useMousePosition from "hooks/useMousePosition"
import { useCursorStore } from "store/cursorStore"

import cx from "classnames"
import gsap from "gsap"
import { useTranslation } from "react-i18next"

type Props = {}

const MagnetCursor = (props: Props) => {
  const cursorDefaultRef = useRef(null)
  const magnetCursorRef = useRef(null)
  const cursorStore = useCursorStore()
  const { clientX, clientY } = useMousePosition()
  const { t } = useTranslation()

  // control screen display
  useEffect(() => {
    const handleMouseEnter = () => {
      if (!cursorStore.visible) cursorStore.toggleVisibility()
    }

    const handleMouseLeave = () => {
      if (cursorStore.visible) cursorStore.toggleVisibility()
    }

    document.body.addEventListener("mouseenter", handleMouseEnter)
    document.body.addEventListener("mouseleave", handleMouseLeave)

    return () => {
      document.body.removeEventListener("mouseenter", handleMouseEnter)
      document.body.removeEventListener("mouseleave", handleMouseLeave)
    }
  }, [cursorStore.visible])

  useEffect(() => {
    if (cursorStore.visible) {
      magnetCursorRef.current &&
        gsap.to(magnetCursorRef.current, {
          x: () => clientX - 10,
          y: () => clientY - 5,
          duration: 0,
        })
    }
  }, [clientX, clientY, cursorStore.visible])

  // useEffect(() => {
  //   console.log("texted", cursorStore.texted)
  //   console.log("seeMore", cursorStore.seeMore)
  //   console.log("default", cursorStore.default)
  // }, [cursorStore])

  return (
    <div
      className={cx(s.magnetCursor, {
        [s.visible]: cursorStore.visible,
      })}
      ref={magnetCursorRef}
    >
      <div className={s.cursor} ref={cursorDefaultRef}>
        <div className={cx(s.inner, { [s.texted]: cursorStore.texted })}>
          <p
            className={cx(s.text, {
              [s.active]: cursorStore.seeMore && !cursorStore.slide,
            })}
          >
            {t("magnetCursor.close")}
          </p>
          <p
            className={cx(s.text, {
              [s.active]: !cursorStore.seeMore && !cursorStore.slide,
            })}
          >
            {t("magnetCursor.seeMore")}
          </p>
          <p className={cx(s.text, { [s.active]: cursorStore.slide })}>
            {t("magnetCursor.drag")}
          </p>
        </div>
        <div
          className={cx(s.inner, {
            [s.default]: cursorStore.default,
            [s.hidden]: cursorStore.hidden,
          })}
        ></div>
      </div>
    </div>
  )
}

export default MagnetCursor
