import s from "assets/scss/DropdownLang.module.scss"

import cx from "classnames"
import gsap from "gsap"
import i18n from "i18n/i18n"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const lngs: any = {
  en: { nativeName: "EN" },
  tr: { nativeName: "TR" },
}

const DropdownLang = () => {
  const handleLang = (selected: string) => {
    gsap.to("body", {
      autoAlpha: 0,
      duration: 0.4,
      onComplete: () => {
        i18n.changeLanguage(selected)
        gsap.to("body", {
          autoAlpha: 1,
          duration: 0.8,
          delay: 0.8,
        })
      },
    })
  }

  const [selectedLang, setSelectedLang] = useState<string>(i18n.language)

  const handleSelect = (lng: string) => {
    setSelectedLang(lng)
  }

  useEffect(() => {
    if (selectedLang) handleLang(selectedLang)
  }, [selectedLang])

  return (
    <div
      className={cx(s.selectWrapper)}
      onClick={() =>
        handleSelect(
          i18n.language === lngs.en.nativeName
            ? lngs.tr.nativeName
            : lngs.en.nativeName
        )
      }
    >
      <p
        className={s.lngText}
        style={{ opacity: i18n.language === lngs.en.nativeName ? "0" : "1" }}
      >
        EN
      </p>
      <p
        className={s.lngText}
        style={{ opacity: i18n.language === lngs.tr.nativeName ? "0" : "1" }}
      >
        TR
      </p>
    </div>
  )
}

export default DropdownLang
