import { useEffect, useState } from "react"
import s from "../assets/scss/Home.module.scss"
import ButtonCircle from "../components/ButtonCircle"

import axios from "axios"
import cx from "classnames"
import { Link } from "react-router-dom"

import MagneticButton from "components/MagneticButton"
import Slider from "components/Slider"
import { IProject } from "models/IProject"
import { Trans, useTranslation } from "react-i18next"
import { api } from "utils/types"
import bullet from "../assets/icon/bulletpoint.svg"
import acc1 from "../assets/img/acc-1.png"
import acc2 from "../assets/img/acc-2.png"
import acc3 from "../assets/img/acc-3.png"
import conversation from "../assets/img/conversation.png"
import keycoLogoPoster from "../assets/img/keyco-logo-poster.png"
import Accordion from "../components/Accordion"
import Footer from "../components/Footer"
import IconArrowSquare from "../components/Icon/IconArrowSquare"
import Img from "../components/Img"
import Marquee from "../components/Marquee"
import ProjectsSlider from "../components/ProjectsSlider"

function Home() {
  const { t, i18n } = useTranslation()
  const obj = {
    consulting: "consulting",
    brandArchitecture: "brandArchitecture",
    brandCoop: "brandCoop",
  }

  type ObjectKey = keyof typeof obj

  const accordionItems = [
    {
      icon: acc1,
      title: {
        part1: t("home.accordion.consulting.title.p1"),
        part2: t("home.accordion.consulting.title.p2"),
      },
      tags: ["Consultancy"],
      content: obj.consulting as ObjectKey,
    },
    {
      icon: acc2,
      title: {
        part1: t("home.accordion.brandArchitecture.title.p1"),
        part2: t("home.accordion.brandArchitecture.title.p2"),
      },
      tags: ["Corporate"],
      content: obj.brandArchitecture as ObjectKey,
    },
    {
      icon: acc3,
      title: {
        part1: t("home.accordion.brandCoop.title.p1"),
        part2: t("home.accordion.brandCoop.title.p2"),
      },
      tags: ["Collaborate", "Communicate", "Connect"],
      content: obj.brandCoop as ObjectKey,
    },
  ]

  const [projects, setProjects] = useState<IProject[]>([])

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        // 👇️ const data: CreateUserResponse
        const { data } = await axios.get<any>(`${api}homeProjects.php`, {
          params: {
            language: i18n.language,
          },
        })
        return data
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message)
          // 👇️ error: AxiosError<any, any>
          return error.message
        } else {
          console.log("unexpected error: ", error)
          return "An unexpected error occurred"
        }
      }
    }

    fetchProjects().then((projects) => {
      setProjects(projects)
    })
  }, [i18n.language])

  return (
    <>
      <main className={s.home}>
        <section className={s.hero}>
          <Slider></Slider>
        </section>
        <section className={s.whatWeDo}>
          <div className={s.doubleTitleW}>
            <h2 className={s.title}>
              <Trans
                i18nKey="home.whatWeDo.title"
                components={{ break: <br /> }}
              >
                {t("home.whatWeDo.title")}
              </Trans>
            </h2>
            <h2 className={s.title} data-parallax data-speed="0.1">
              <Trans
                i18nKey="home.whatWeDo.title"
                components={{ break: <br /> }}
              >
                {t("home.whatWeDo.title")}
              </Trans>
            </h2>
          </div>
          <div className={s.textW}>
            <p className={s.par}>
              <Trans
                i18nKey="home.whatweDo.p1"
                components={{ bold: <strong /> }}
              >
                {t("home.whatWeDo.p1")}
              </Trans>
            </p>
            <p className={s.par}>
              <Trans
                i18nKey="home.whatweDo.p2"
                components={{ bold: <strong /> }}
              >
                {t("home.whatWeDo.p2")}
              </Trans>
            </p>
          </div>
        </section>
        <section className={s.contactUs}>
          <div className={cx(s.imgW, s.keycoPoster)} data-scaling-img-wr>
            <div data-scaling-img>
              <Img src={keycoLogoPoster} objectFit="cover"></Img>
            </div>
          </div>
          <div
            className={cx(s.imgW, s.conversation)}
            data-parallax
            data-speed="0.1"
          >
            <Img src={conversation} objectFit="cover"></Img>
          </div>

          <Link to={`/${t("routes.contact")}`} className={s.btnW}>
            <MagneticButton>
              <div className={s.magneticW}>
                <ButtonCircle
                  text={{
                    part1: t("home.contactUs.btnText.p1"),
                    part2: t("home.contactUs.btnText.p2"),
                  }}
                  icon={<IconArrowSquare fill="#3b3b3b" />}
                />
              </div>
            </MagneticButton>
          </Link>
        </section>
        <section className={s.marqueeW}>
          <Marquee>
            <div className={s.textW} data-marquee-sliding-inner>
              <h2 className={s.text}> {t("home.marquee.text")}</h2>
              <span className={s.iconW}>
                <Img src={bullet} objectFit="contain" />
              </span>
            </div>
          </Marquee>
        </section>
        <section className={s.accordionW}>
          <Accordion items={accordionItems}></Accordion>
        </section>
        <section className={s.projects}>
          <div className={cx(s.doubleTitleW, s.projectsTitle)}>
            <h2 className={s.title}>{t("home.projects.title")}</h2>
            <h2 className={s.title} data-parallax data-speed="0.1">
              {t("home.projects.title")}
            </h2>
          </div>
          {Array.isArray(projects) && <ProjectsSlider projects={projects} />}
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Home
