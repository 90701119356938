import React from "react"
import s from "../assets/scss/Sidebar.module.scss"

import cx from "classnames"

import logo from "../assets/img/keyco-logo.png"
import Img from "./Img"
import { useMenuStore } from "../store/menuStore"
import { Link } from "react-router-dom"
import DropdownLang from "./DropdownLang"

type Props = {}

const Sidebar = (props: Props) => {
  const menuStore = useMenuStore()

  const handleMenu = () => {
    menuStore.toggleMenu()
  }

  return (
    <aside className={s.sidebar} data-fixed-item>
      <Link to="/" className={s.logoW}>
        <Img src={logo} objectFit="contain"></Img>
      </Link>

      <div className={s.langW}>
        <DropdownLang></DropdownLang>
      </div>

      <div
        className={cx(s.xBurger, { [s.open]: menuStore.open })}
        onClick={handleMenu}
      >
        <div className={s.lines}>
          <div className={cx(s.line, s.horizontal1)}></div>
          <div className={cx(s.line, s.horizontal2)}></div>
          <div className={cx(s.line, s.horizontal3)}></div>
          <div className={s.cross1W}>
            <div className={s.cross1}></div>
          </div>
          <div className={s.cross2W}>
            <div className={s.cross2}></div>
          </div>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
