import React from "react"
import s from "assets/scss/BlogCard.module.scss"
import { Link } from "react-router-dom"
import { IBlog } from "models/IBlog"

import Img from "./Img"

type Props = {
  blog: IBlog
}

const BlogCard: React.FC<Props> = ({ blog }) => {
  return (
    <div className={s.blogCard}>
      <div className={s.imgW}>
        <Img src={blog.image} objectFit="cover"></Img>
      </div>
      <h5 className={s.title}>{blog.title}</h5>
      <p className={s.text}>{blog.summary}</p>
      <Link to={`/blog/${blog.url}`} className={s.readMore}>
        Detaylı Oku
      </Link>
    </div>
  )
}

export default BlogCard
