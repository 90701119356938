import pdfPrivacy from "assets/pdf/PrivacyPolicy.pdf"
import pdfKvkk from "assets/pdf/PersonalDataProcessingInformativeNote.pdf"

export const en = {
  translation: {
    routes: {
      home: "",
      projects: "projects",
      contact: "contact",
      blog: "blog",
    },
    home: {
      hero: {
        small: "We combine sectoral expertise with research-backed data.",
      },
      whatWeDo: {
        title: "What does <br> it do?",
        knowHow: "know-how",
        p1: "Keyco. is a boutique business development consultancy agency founded with the intention of implementing its business <bold>know-how</bold> to firms and investors that are at their project development stages. Keyco. fuses its sectoral experiences with extensive market research and puts it in use of its global customer base. ",
        p2: "For investors and startup owners in early stages of firm establishment, Keyco. provides services such as; <bold>Performance enhancing financial reports, analysis tools, project & concept development, design management, coordination and salesmarketing consultancy.</bold>",
      },
      contactUs: {
        btnText: { p1: "Contact", p2: "Us" },
      },
      marquee: {
        text: "Range of Services",
      },
      accordion: {
        consulting: {
          title: { p1: "Investor", p2: "Counseling" },
          tags: { p1: "Consultancy" },
          content: {
            p1: "We determine the ideal market positioning for investors and provide consultancy through <bold>R&D</bold> work and financial forecast reports.",
            p2: "We generate impactful strategies for your business that stand out and touch the hearts of your target customers.",
          },
        },
        brandArchitecture: {
          title: { p1: "Brand", p2: "Architecture" },
          tags: { p1: "Corporate" },
          content: {
            p1: "<span>360’ Business Development Support</span> For newly emerging and mature businesses; We analyze your future financial objectives and prepare roadmaps that maximizes your valuable assets.",
            p2: "“Creating the backbone of your marketing and brand strategies for a strong and fundamental brand architecture.”",
            list: {
              title: "Brand Architecture Services:",
              items: {
                p1: " Feasibility Assessment",
                p2: "Financial Forecast Report",
                p3: "Narrative & Storytelling",
                p4: "Concept Creation",
                p5: "Design Management",
                p6: "Project Development",
                p7: "Strategic Marketing",
                p8: "Coordination",
                p9: "Sales Market Determination",
                p10: "Market Positioning",
                p11: "Sales & Marketing Support",
              },
            },
          },
        },
        brandCoop: {
          title: { p1: "Brand", p2: "Collaboration" },
          tags: { p1: "Collaborate", p2: "Communicate", p3: "Connect" },
          content: {
            p1: "We create powerful business partnerships by connecting you with your target audience in order to enhance your sales performance. By utilizing Keyco.’s network, we unveil creative business ideas and bind know-how strategies amongst businesses.",
            p2: "We build strategies to flourish your business and consult you in your brand’s growth journey.",
          },
        },
      },
      projects: {
        title: "Projects",
        btnText: { p1: "All", p2: "Projects" },
      },
    },
    contact: {
      title: "Let’s write your story together.",
      form: {
        name: "Name Surname*",
        company: "Company Name*",
        phone: "Phone Number",
        email: "Email Address*",
        message: "Message*",
        privacy: {
          p1: "As Keyco. we protect your privacy. We solely use your private information to update you on our desired services.",
          p2: "Privacy Policy",
        },
        btnText: "Send",
      },
      moreInfo: {
        title: "Got questions?",
        text: "Mail us at <linkk>info@keyco.com.tr</linkk><br> to learn more.",
      },
      copyright: "KEYCO. © 2022. ALL RIGHTS RESERVED.",
    },
    footer: {
      title: { p1: "Are you ready to grow", p2: "and expand?" },
      btnText: { p1: "Contact", p2: "Us" },
      subscribe: {
        title: "SUBSCRIBE TO OUR NEWSLETTER.",
        text: "Sign-up to our newsletter and be the first to learn about new talks and reads.",
        label: "Email Address",
        confirmation: {
          p1: "As Keyco. we protect your privacy. We solely use your private information to update you on our desired services.",
          p2: "Privacy Policy",
        },
      },
      copyright: "KEYCO. © 2022. ALL RIGHTS RESERVED.",
      kvkk: { ui: "INFORMATION SECURITY POLICY", url: pdfKvkk },
      privacy: { ui: "PRIVACY POLICY", url: pdfPrivacy },
      cookie: { ui: "COOKIES", url: "cookies" },
    },
    blog: {
      searchbarLabel: "Search",
    },
    menu: {
      projects: { ui: "Projects", url: "projects" },
      blog: { ui: "Blog", url: "blog" },
      contact: { ui: "Contact", url: "contact" },
    },
    projects: {
      title: "Projects",
    },
    magnetCursor: {
      seeMore: "See More",
      drag: "Drag",
      close: "Close",
    },
    social: {
      instagram: "https://instagram.com/keyco.company",
      linkedin: "https://linkedin.com/keyco.company",
    },
  },
}

export default en
