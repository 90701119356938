import React from "react";

type Props = {
  fill: string;
  scale?: number;
};

const IconBulletPoint = ({ fill = "#000", scale = 1 }: Props) => {
  return (
    <div
      style={{
        transform: `scale(${scale})`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path
          id="bulletpoint"
          d="M18,9a8.87,8.87,0,0,1-9,9A8.87,8.87,0,0,1,0,9,8.869,8.869,0,0,1,9,0a8.869,8.869,0,0,1,9,9M4.975,16A8.364,8.364,0,0,0,8.6,17.049L1,9.45a8.185,8.185,0,0,0,1.05,3.625ZM9.9,17a7.67,7.67,0,0,0,2.05-.5L1.525,6.075A8.1,8.1,0,0,0,1.049,8.15ZM2.975,3.65a8.03,8.03,0,0,0-1.05,1.5L12.875,16.1a10.177,10.177,0,0,0,1.5-1.05Zm12.05,10.7a8.047,8.047,0,0,0,1.05-1.5L5.125,1.9a10.164,10.164,0,0,0-1.5,1.049ZM8.1,1a7.67,7.67,0,0,0-2.05.5L16.475,11.925A8.093,8.093,0,0,0,16.95,9.85ZM17,8.55a8.186,8.186,0,0,0-1.05-3.625L13.025,2A8.377,8.377,0,0,0,9.4.95Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default IconBulletPoint;
