import React from "react";

type Props = {
  fill: string;
  rotate?: number;
  scale?: number;
};

const IconArrowSlider = ({ fill = "#000", rotate = 0, scale = 1 }: Props) => {
  return (
    <div
      style={{
        transform: `rotate(${rotate}deg) scale(${scale})`,
        transformOrigin: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7.756"
        height="6.032"
        viewBox="0 0 7.756 6.032"
      >
        <path
          id="Path_46"
          data-name="Path 46"
          d="M2746.39,363.5l-.984-1.022v.01l-1.923-1.988h-1.966l2.211,2.27h-5.093v1.449h5.093l-2.211,2.313h1.966l1.923-2v0l.984-1.023v0Z"
          transform="translate(-2738.634 -360.5)"
          fill={fill}
        />
      </svg>
    </div>
  );
};

export default IconArrowSlider;
