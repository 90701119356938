import { useEffect } from "react"
import s from "../assets/scss/Menu.module.scss"

import cx from "classnames"
import { Link, useLocation } from "react-router-dom"

import { useMenuStore } from "store/menuStore"
import { useCursorStore } from "store/cursorStore"
import { useLockedBody } from "hooks"
import { useTranslation } from "react-i18next"

const Menu = () => {
  const { t } = useTranslation()
  const menuStore = useMenuStore()
  const cursorStore = useCursorStore()
  const [, setLocked] = useLockedBody(false, "root")
  const location = useLocation()

  // const handleMenu = () => {
  //   menuStore.toggleMenu()
  // }

  useEffect(() => {
    if (menuStore.open) {
      setLocked(true)
    } else {
      setLocked(false)
    }
  }, [menuStore.open])

  useEffect(() => {
    if (menuStore.open) menuStore.toggleMenu()
  }, [location])

  return (
    <div className={cx(s.menuW, { [s.open]: menuStore.open })}>
      <nav className={s.nav}>
        <ul className={s.links}>
          <li className={s.linkW}>
            <div className={s.circle}></div>
            <div className={s.indexW}>
              <small className={s.index}>1</small>
            </div>
            <Link
              className={s.link}
              to={`/${t("routes.projects")}`}
              onMouseEnter={() => cursorStore.toggleHide()}
              onMouseLeave={() => {
                cursorStore.toggleHide()
                if (!cursorStore.default) cursorStore.toggleDefault()
              }}
            >
              {t("menu.projects.ui")}
            </Link>
          </li>
          <li className={s.linkW}>
            <div className={s.circle}></div>
            <div className={s.indexW}>
              <small className={s.index}>2</small>
            </div>
            <Link
              className={s.link}
              to="/blog"
              onMouseEnter={() => cursorStore.toggleHide()}
              onMouseLeave={() => {
                cursorStore.toggleHide()
                if (!cursorStore.default) cursorStore.toggleDefault()
              }}
            >
              {t("menu.blog.ui")}
            </Link>
          </li>
          <li className={s.linkW}>
            <div className={s.circle}></div>
            <div className={s.indexW}>
              <small className={s.index}>3</small>
            </div>
            <Link
              className={s.link}
              to={`/${t("routes.contact")}`}
              onMouseEnter={() => cursorStore.toggleHide()}
              onMouseLeave={() => {
                cursorStore.toggleHide()
                if (!cursorStore.default) cursorStore.toggleDefault()
              }}
            >
              {t("menu.contact.ui")}
            </Link>
          </li>
        </ul>
      </nav>
      <div className={s.social}>
        <a
          href={`${t("social.instagram")}`}
          target="_blank"
          rel="noreferrer"
          className={s.link}
        >
          INSTAGRAM
        </a>
        {/* <a
          href={`${t("social.linkedin")}`}
          target="_blank"
          rel="noreferrer"
          className={s.link}
        >
          LINKEDIN
        </a> */}
      </div>
    </div>
  )
}

export default Menu
